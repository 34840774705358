import CryptoJS from "crypto-js";

if (
  typeof response !== "undefined" &&
  response !== null &&
  response.length > 0
) {
  response.token = CryptoJS.MD5(
    new Date().getTime() + "-" + response.token
  ).toString();
}
