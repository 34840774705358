// Stripping html tags on text page in all fields except ckeditor.
window.addEventListener("paste", e => {
  e.preventDefault();

  if (e.clipboardData) {
    content = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand('insertText', false, content);
  } else if (window.clipboardData) {
    content = window.clipboardData.getData('Text');
    document.selection.createRange().pasteHTML(content);
  }
});
